<template>
  <div class="business_sub_page">
    <div class="topic_panel">
      <div class="topic_desc">
        {{ titleZh  }}
      </div>
      <div class="topic_desc_en">
        {{ titleEn   }}
      </div>
      <div class="topic_desc_line"></div>
    </div>
    <div class="main_panel">
      <div class="module_desc" v-html="thematicDesc">
      </div>
      <div class="section_content">
        <div class="section_title section_title_feature">服务特色</div>
        <div class="section_card">
          <div class="section_card_item" v-for="(cItem,cIdx) in cards" :key="cIdx">
            <div class="section_card_line" v-for="(lItem,lIdx) in cItem" :key="lIdx">
              <div class="section_card_icon"></div>
              <div class="section_card_label">
                {{lItem}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section_content">
        <div class="section_title section_title_range">服务范围</div>
        <div class="section_desc">
          <div class="section_desc_info">
            <div class="section_desc_info_label">
              {{rangesTitle}}
            </div>
            <div class="section_desc_info_value" v-html="rangesDesc">
            </div>
          </div>
          <div class="section_desc_img">
            <img :src="rangesPic">
          </div>
        </div>
      </div>
      <div class="detail_panel">
        <div class="detail_title">
          有效性研究:
        </div>
        <div class="detail_content">
          <div class="detail_col" v-for="(colItem,colIdx) in detailInfos" :key="colIdx">
            <div class="detail_row" v-for="(rowItem,rowIdx) in colItem" :key="rowIdx">
              <div class="detail_label">
                {{rowItem.label}}
              </div>
              <div class="detail_info_line" v-for="(detailItem,detailIdx) in rowItem.details" :key="detailIdx">
                <div class="detail_info_line_icon"></div>
                <div class="detail_info_line_value" v-html="detailItem">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
  components: {
  },
  // 定义属性
  data () {
    return {
      "rangesTitle":":",
      "rangesDesc":"",
      "rangesPic":"",
      "thematicDesc":"",
      "cards":[],
      "detailInfos":[],
      "titleZh":"",
      "titleEn":""
    }
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 获取页面数据
    getData(){
      let _this = this;
      _this.$http.get(`${window.SEVERURL}/safeglp/generator/configs/queryByType?primaryTitle=safeService&secondaryTitle=pharmacologicalAndPharmacodynamicEvaluation`).then(res=>{
        if(res.status==200&&res.data.code==0){
          // 获取配置数据
          let jsonStr = res.data.page?res.data.page.configs:"{}";
          let jsonStrPure = jsonStr.replace(/\n/g,"").replace(/\r/g,"");
          let configData = JSON.parse(jsonStrPure); 
          
          // 处理配置数据
          _this.rangesTitle = configData.rangesTitle;
          _this.rangesDesc = configData.rangesDesc;
          _this.rangesPic = `${window.PICSEVERURL}/${configData.rangesPic}`;
          _this.thematicDesc = configData.thematicDesc;
          _this.cards = configData.cards;
          _this.detailInfos = configData.detailInfos;
          _this.titleZh = configData.titleZh;
          _this.titleEn = configData.titleEn;
        }else{
          _this.$notify({
            title: '失败',
            message: "药理药效学评价数据请求失败",
            type: 'error'
          });
        }
      });
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getData();
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="scss">
.business_sub_page {
  width: 100%;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .topic_panel {
    max-width: 1520px;
    min-width: 1200px;
    width: 100%;
    margin-top: 70px;
    margin-bottom: 66px;
    .topic_desc{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 42px;
      color: #222222;
      line-height: 46px;
      margin-bottom: 10px;
    }
    .topic_desc_en{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 24px;
      color: #BCBDBE;
      line-height: 28px;
      margin-bottom: 16px;
    }
    .topic_desc_line{
      width: 76px;
      height: 3px;
      background: #0086D1;
    }
  }
  .main_panel {
    max-width: 1520px;
    min-width: 1200px;
    width: 100%;
    margin-bottom: 43px;
    .module_desc{
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 18px;
      color: #333333;
      line-height: 32px;
      margin-bottom: 90px;
    }
    .section_content{
      .section_title{
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 32px;
        color: #000000;
        height: 35px;
        line-height: 35px;
        padding-left: 57px;
        box-sizing: border-box;
        &.section_title_feature{
          background: url('./images/titleBg@feature.png') 0 0 no-repeat;
          background-size: auto 34px;
          background-position: center left;
        }
        &.section_title_range{
          background: url('./images/titleBg@range.png') 0 0 no-repeat;
          background-size: auto 34px;
          background-position: center left;
        }
      }
      .section_card{
        margin-top: 46px;
        margin-bottom: 60px;
        display: flex;
        background: #F6F7F9;
        padding: 28px 50px;
        box-sizing: border-box;
        .section_card_item{
          flex: 1;
          overflow: hidden;
          margin-right: 50px;
          &:last-child{
            margin-right: 0;
          }
          .section_card_line{
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 18px;
            color: #333333;
            display: flex;
            align-items: center;
            height: 48px;
            .section_card_icon{
              width: 15px;
              height: 15px;
              background: url("./images/icon@checked.png") 0 0 no-repeat;
              background-size: 100% auto;
              background-position: center left;
              margin: 10px 7px 10px 0;
            }
          }
        }
      }
      .section_desc{
        display: flex;
        margin-top: 36px;
        margin-bottom: 40px;
        .section_desc_info{
          flex: 1;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 30px;
          box-sizing: border-box;
          border: 1px solid #d5d5d5;
          .section_desc_info_label{
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 24px;
            color: #333333;
            line-height: 43px;
            margin-bottom: 17px;
          }
          .section_desc_info_value{
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 18px;
            color: #333333;
            line-height: 32px;
          }
        }
        .section_desc_img{
          display: flex;
          img{
            height: 264px;
          }
        }
      }
    }
    
    .detail_panel{
      .detail_title{
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 24px;
        color: #333333;
        line-height: 43px;
        margin-bottom: 21px;
      }
      .detail_content{
        display: flex;
        .detail_col{
          flex: 1;
          overflow: hidden;
          margin-right: 40px;
          display: flex;
          flex-direction: column;
          &:last-child{
            margin-right: 0;
          }
          .detail_row{
            margin-bottom: 20px;
            background: #F6F7F9;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 18px 28px;
            flex: 1;
            &:last-child{
              margin-bottom: 0;
            }
            .detail_label{
              font-family: PingFang SC, PingFang SC;
              font-weight: 600;
              font-size: 24px;
              color: #0086D1;
              line-height: 53px;
              margin-bottom: 8px;
            }
            .detail_info_line{
              display: flex;
              align-items: flex-start;
              .detail_info_line_icon{
                width: 15px;
                height: 15px;
                background: url("./images/icon@checked.png") 0 0 no-repeat;
                background-size: 100% auto;
                background-position: center left;
                margin: 10px 7px 10px 0;
              }
              .detail_info_line_value{
                flex: 1;
                overflow: hidden;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 18px;
                color: #333333;
                line-height: 35px;
              }
            }
          }
        }
      }
    }
  }
}
</style>